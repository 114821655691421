import React, { useState, useEffect } from "react";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "../util/CheckoutForm";
import Discounts from "./Discounts";
import styled from "styled-components";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Why = () => {
    return (
    <Container>
        <Wrapper>
            <Row className="justify-content-md-center align-items-md-stretch">
                <Col xs lg="8">
                    <h2 style={{fontFamily:"roboto"}}>Why MAARP?</h2>
                    <Exp>
                        MAARP (The Millennial Advocacy and Action Resource Platform) is an interest group specifically designed for millennials in today's sociopolitical landscape. Millennials born between 1981 and 1996, constitute a significant and distinct demographic cohort that faces unique challenges and opportunities.
                        Millennials should be able to organize and advance issues at all times and through all avenues. Millennial progress should not only be limited to political means, around political seasons, or behind individual political candidates.
                    </Exp>
                    <ul>
                        <li>
                            <span>Generational Challenges:</span> Millennials have confronted distinctive challenges that set them apart from previous generations. These include mounting student loan debt, the burden of healthcare costs, a changing job market with increased gig work, and the economic fallout from the 2008 financial crisis and, more recently, the COVID-19 pandemic. MAARP can address these issues directly and work to find solutions inside and out of the political arena.
                        </li>
                        <li>
                            <span> Market Influence:</span> Millennials make up a substantial portion of the population. To ensure that their concerns, values, and perspectives are adequately represented in the consumer market, MAARP can bargain and boycott for millennial values in products and services.     
                        </li>
                        <li>
                            <span> Political Influence:</span> As millennials increasingly become a dominant voting bloc, their political influence is growing. MAARP can help millennials consolidate their political power, endorse candidates who align with their values, and impact elections at all levels of government.       
                        </li>
                        <li>
                            <span>Technology and Innovation:</span> Millennials are the first generation to grow up with the internet and rapidly evolving technology. They are at the forefront of digital innovation and can offer valuable insights into issues like data privacy, online security, social media addiction, and the regulation of emerging technologies like AI and blockchain.            
                        </li>
                        <li>
                            <span>Environmental Concerns:</span> Millennials are often characterized by their strong commitment to environmental sustainability and climate action. MAARP can advocate for policies that align with these values, such as renewable energy initiatives, carbon emissions reduction, and conservation efforts.           
                        </li>
                        <li>
                            <span>Civic Engagement:</span> Millennials have demonstrated a strong interest in civic engagement and activism. An interest group can help harness this energy and channel it into meaningful political action and advocacy.           
                        </li>
                        <li>
                            <span>Digital Advocacy:</span> Millennials are well-versed in social media and digital communication, making them adept at mobilizing and amplifying their voices. MAARP can utilize these skills to engage with policymakers, raise awareness, and build a broad support base.          
                        </li>
                        <li>
                            <span>Interconnected Global Issues:</span> Millennials face global challenges such as international conflict, migration, and public health crises. MAARP can promote international cooperation and advocate for policies that address these issues effectively.            
                        </li>
                        <li>
                            <span>Generational Solidarity:</span> By uniting millennials around common goals and concerns, MAARP can foster a sense of generational solidarity. This unity can empower millennials to drive change, hold policymakers accountable, and shape the future in a way that benefits not only their generation but society as a whole. MAARP can help millennials consolidate their political and consumer power, endorse candidates and companies who align with their values, and impact elections at all levels of government.       
                        </li>
                    </ul>
                    <Exp>
                        MAARP aims to address the unique challenges, values, and opportunities that this generation faces. MAARP can be a catalyst for positive change, advocating for policies and initiatives that reflect the interests and aspirations of millennials while contributing to a more inclusive and equitable society. Please join today.     
                    </Exp>
                </Col>
            </Row>
        </Wrapper>
    </Container>
    );
}

const Wrapper = styled.section`
  margin-top:20px;
  background-color:white;
  color:black;
  text-align:left;
  div {
    font-size:18px;
  }
  span {
    font-weight:bold;
  } 
`;

const Exp = styled.div`
margin-bottom:20px;
background-color:white;
color:black;
span {
    font-weight:bold;
}
`


export default Why