import React, { useState, useEffect } from "react";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "../util/CheckoutForm";
import "bootstrap/dist/css/bootstrap.min.css";
import ListGroup from 'react-bootstrap/ListGroup';
import styled from "styled-components";
import * as Icon from 'react-feather';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const Discounts = () => {
    return (
        <Container>
            <Row className="justify-content-md-center align-items-md-stretch">
              <Col xs lg="6">
                <Disc>
                <h2 style={{fontFamily:"roboto"}}>For a dollar per month get access to:</h2>
                <ListGroup variant="flush">
                <ListGroup.Item><Icon.CheckCircle style={{margin:'0 10px'}}/>Information and resources you can trust to gain a better quality of life.</ListGroup.Item>
                <ListGroup.Item><Icon.CheckCircle style={{margin:'0 10px'}}/>Discounts on food, travel, services and online retailers from Millennial businesses.</ListGroup.Item>
                <ListGroup.Item><Icon.CheckCircle style={{margin:'0 10px'}}/>Content and tools accessible from anywhere.</ListGroup.Item>
                <ListGroup.Item><Icon.CheckCircle style={{margin:'0 10px'}}/>Subscription to MAARP newsletter.</ListGroup.Item>
                </ListGroup>
                </Disc>
              </Col>
            </Row>
        </Container>
    )
}

const Disc = styled.div`
    margin: 60px auto;
`

export default Discounts