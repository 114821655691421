import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import { getApp } from "@firebase/app";
import { getStripePayments } from "@stripe/firestore-stripe-payments";
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'

const firebaseConfig = {
  apiKey: "AIzaSyDRqmIrhlBclcxIraPQtuxC4UXHS7_7Lnk",
  authDomain: "maarp-ae451.firebaseapp.com",
  projectId: "maarp-ae451",
  storageBucket: "maarp-ae451.appspot.com",
  messagingSenderId: "214538461316",
  appId: "1:214538461316:web:8be3855cb1ab717a7be2d1",
  measurementId: "G-SKKX09F3DY"
};
const app = firebase.initializeApp(firebaseConfig);
//const app = getApp();
const payments = getStripePayments(app, {
  productsCollection: "products",
  customersCollection: "customers",
});


  var ui = new firebaseui.auth.AuthUI(firebase.auth());

  var uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: function(authResult, redirectUrl) {
        console.log("PPPPPPPPPPP", authResult, redirectUrl)
        firebase.auth()
            .setCustomUserClaims(authResult.user.uid, { admin: true })
            .then(() => {
                // The new custom claims will propagate to the user's ID token the
                // next time a new one is issued.
            });
        // User successfully signed in.
        // Return type determines whether we continue the redirect automatically
        // or whether we leave that to developer to handle.
        return true;
      },
      uiShown: function() {
        document.getElementById('loader').style.display = 'none';
      }
    },
    // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
    signInFlow: 'popup',
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    // Terms of service url.
    tosUrl: '<your-tos-url>',
    privacyPolicyUrl: '<your-privacy-policy-url>'
  };

  ui.start('#firebaseui-auth-container', uiConfig);

  function SignInScreen() {
    return(
        <>
        <h1>Create an account with MAARP</h1>
        <div id="firebaseui-auth-container"></div>
        <div id="loader">Loading...</div>
        </>
    )
  }
  
  export default SignInScreen;