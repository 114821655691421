import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Footer = () => {
    const [clientSecret, setClientSecret] = useState("");

    return (
      <Wrapper>
        Privacy policy / Terms of use copyright 2023
      </Wrapper>
      );
}

const Wrapper = styled.section`
  width: 100%;
  height: 100px;
  background-color: black;
  display:flex;
  align-content: center;
  justify-content: center;
  align-items: center;

  div {
    font-size:18px;
  }
`;



export default Footer